// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-frames-canvas-pong-index-tsx": () => import("./../../../src/pages/frames/canvas-pong/index.tsx" /* webpackChunkName: "component---src-pages-frames-canvas-pong-index-tsx" */),
  "component---src-pages-frames-react-json-print-index-tsx": () => import("./../../../src/pages/frames/react-json-print/index.tsx" /* webpackChunkName: "component---src-pages-frames-react-json-print-index-tsx" */),
  "component---src-pages-frames-react-json-print-usage-tsx": () => import("./../../../src/pages/frames/react-json-print/usage.tsx" /* webpackChunkName: "component---src-pages-frames-react-json-print-usage-tsx" */),
  "component---src-pages-frames-use-memo-index-tsx": () => import("./../../../src/pages/frames/use-memo/index.tsx" /* webpackChunkName: "component---src-pages-frames-use-memo-index-tsx" */),
  "component---src-pages-frames-use-memo-no-memo-tsx": () => import("./../../../src/pages/frames/use-memo/no-memo.tsx" /* webpackChunkName: "component---src-pages-frames-use-memo-no-memo-tsx" */),
  "component---src-pages-frames-use-memo-ref-eq-nomemo-tsx": () => import("./../../../src/pages/frames/use-memo/ref-eq-nomemo.tsx" /* webpackChunkName: "component---src-pages-frames-use-memo-ref-eq-nomemo-tsx" */),
  "component---src-pages-frames-use-memo-ref-eq-tsx": () => import("./../../../src/pages/frames/use-memo/ref-eq.tsx" /* webpackChunkName: "component---src-pages-frames-use-memo-ref-eq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../../../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-templates-articles-index-tsx": () => import("./../../../src/templates/articles/index.tsx" /* webpackChunkName: "component---src-templates-articles-index-tsx" */),
  "component---src-templates-projects-index-tsx": () => import("./../../../src/templates/projects/index.tsx" /* webpackChunkName: "component---src-templates-projects-index-tsx" */),
  "component---src-templates-tag-search-index-tsx": () => import("./../../../src/templates/tag-search/index.tsx" /* webpackChunkName: "component---src-templates-tag-search-index-tsx" */)
}

